<template lang="pug">
  b-container(fluid)
    b-row.mb-3
      b-col
        h3.mb-3 Usuarios
      b-col(cols="auto")
        b-button(variant="success", :to="{name: 'Usuario', params:{usuario_id: 'new'} }") Crear usuario
    b-table(:items="usuarios", :fields="fields", responsive, hover, no-border-collapse, @row-clicked="openRow", show-empty)
      template(#empty)
        .text-center No hay datos para mostrar
      template(#cell(status)="{item}")
        b-badge(:variant="getStatusVariant(item.status)") {{ item.status || 'Sin status' }}
      template(#cell(createdAt)="{item}")
        span {{ $moment(item.createdAt).format('DD MMMM YYYY, HH:mmA') }}
      template(#cell(updatedAt)="{item}")
        span {{ $moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA') }}
    .d-flex.align-items-center.mb-3
      b-pagination.mb-0(v-model="paginationPage", :total-rows="paginationTotal", :per-page="paginationLimit", @input="getUsuarios")
      b-spinner.ml-3(variant="primary", small, v-if="loadingData")
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
const usuarioController = createNamespacedHelpers('usuario');

export default {
  data () {
    return {
      fields: [
        {
          key: 'usuario_id',
          label: 'Id'
        },
        {
          key: 'first_name',
          label: 'Nombre'
        },
        {
          key: 'last_name',
          label: 'Apellido'
        },
        'email',
        'status',
        {
          key: 'updatedAt',
          label: 'Actualizado'
        },
        {
          key: 'createdAt',
          label: 'Creado'
        }
      ]
    }
  },
  computed: {
    ...usuarioController.mapState({
      usuarios: state => state.usuarios
    })
  },
  methods: {
    ...mapActions(['usuario/all']),
    getStatusVariant (status) {
      switch (status) {
        case 'entrada':
          return 'success';
        case 'salida':
          return 'secondary';
        default:
          return 'light';
      }
    },
    getUsuarios () {
      this.loadingData = true;
      this['usuario/all']({
        params: {
          page: this.paginationPage,
          limit: this.paginationLimit
        },
        callback: res => {
          this.loadingData = false;

          if (res.success) {
            this.paginationTotal = res.total
            this.paginationHasNextPage = res.hasNextPage
          } else {
            this.$bvToast.toast(`Ocurrió un error obteniendo los usuarios. Por favor intenta de nuevo.`, {
              title: 'Ocurrió un error',
              variant: 'danger'
            })
          }
        }
      })
    },
    openRow (item) {
      this.$router.push({name: 'Usuario', params: {usuario_id: item.usuario_id} });
    }
  },
  mounted () {
    this.getUsuarios()
  }
}
</script>

<style>

</style>